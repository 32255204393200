//Color definition

:root {
  --mid-grey:#d8d8d8;
  --light-grey:#F7F7F7;
  --anchor-grey:#f8f9fa;
  --accordion-grey:#edeef0;
}

$theme-colors: (
  "primary": #6480a7,
  "danger": #ff4136, 
  "secondary": #F29800,
  "dark" : #374962
);


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

//Bootrsrap classes overwrite

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

.accordion-button{
  background-color: var(--accordion-grey);
}

.accordion-button:not(.collapsed){
  background-color: var(--mid-grey) !important;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  color: white !important;
}

.badge-primary {
  color: white !important;
}

.bg-primary, .bg-secondary{
  font-weight: 400 !important;
}

h1 {
  font-size : 40px;
  padding-left: 2px;
}

h2 {
  font-size : 28px;
}

h2 > .badge {
  font-size : 15px !important;
  vertical-align : middle;
}

h1 > .badge {
  font-size : 12px !important;
  vertical-align : middle;
}

.tooltip-inner {
  background-color: var(--mid-grey) !important;
  color : black;
}

.tooltip-arrow::before {
  border-bottom-color: var(--mid-grey) !important;
}

//General
.loadingSpinner{
  margin-top: 200px;
  text-align: center;
}

//NavBar
.global-nav-bar{
  background-color: var(--light-grey);
}

.navBarLogo {
  height: auto;
  max-width: 80px;
  margin-top: -3px;
  padding-left : 5px;
}

@media (max-width: 1000px) {
  .nav-item-text{
    display: none;
  }
}
@media (min-width: 1001px) {
  .nav-item-icon{
    display: none;
  }
}

//Footer
.envFileFooter {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  vertical-align : middle;
}

//Pages
.anchor {
  display:block;
  padding-top:65px;
  margin-top:-65px;
}

.anchor-side {
  position: absolute;
  padding: 5px;
  width: 220px;
  background-color: var(--anchor-grey);
  z-index: 100;
  overflow-y: auto;
  max-height: calc(100vh - 57px);
  word-wrap: break-word;
  border-top : none !important;
  border-radius: 0px 0px 5px 0px;
}

.downloadTd {
height:calc(100vh - 72px);
overflow-y: auto;
margin-left: 5px;
margin-right: 5px;
}